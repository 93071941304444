import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer';
import '../hamcss/investor.css';
import investerBanner from '../assests/investor relationship/handshake.webp';
import { Link } from 'react-router-dom';
import b from '../assests/sgPdf/Terms and conditions of appointment of independent directors.pdf';
import d from '../assests/sgPdf/CODE of Conduct for Directors & Senior management MSL.a67eb8a352783d25f9e1.pdf';
import cc from '../assests/sgPdf/CODE of Conduct for Directors & Senior management.pdf';
import m from '../assests/sgPdf/Credit Rating.pdf';
import p16 from '../assests/sgPdf/17.policies/Policy for determination of materiality of event.pdf';
import p21 from '../assests/sgPdf/17.policies/Whistle Blower Policy.pdf';
import bd from '../assests/sgPdf/3. Committe-Board-11.05.2023.pdf';
import bd1 from '../assests/sgPdf/Committe-Board-28.05.2024.pdf';
import bd2 from '../assests/sgPdf/List of Committees w.e.f. 16.10.2024.pdf';
import bd3 from '../assests/sgPdf/List of Committees w.e.f. 16.10.2024 (2).pdf';
import bd4 from '../assests/sgPdf/List of Committees w.e.f.  19.11.2024 (1).pdf';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import DotRing from '../component/dot/DotRing';
import dividentD from '../assests/sgPdf/Divident diistributer policy/Dividend Distribution policy.pdf';
import u from '../assests/sgPdf/Employee Benefit Scheme.pdf';
import ab from '../assests/sgPdf/Brief Profile of Board of Directors.pdf';
import aa from '../assests/sgPdf/Memorandum and Articles of Association.pdf';
import q from '../assests/sgPdf/30(5)-Intimation.pdf';
const Invester = () => {

    return (
        <>

            <DotRing />

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    backgroundImage: `url(${investerBanner})`,
                }}>
                    <div className="container">
                        <h2>Investor Relations</h2>
                    </div>
                </div>
            </section>


            <div className='main_investor_containner'>
                <h3 style={{ textAlign: 'center' }}>Disclosure under Regulation 46 of SEBI (LODR) Regulations</h3>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '840px' }}>

                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>a. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Details of Business </p>
                            </div>
                            <Link to={'/details-business'}>  <button className='investor_button'> click here </button></Link>
                        </div>
                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>aa. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}>  Memorandum of Association and Articles of Association </p>
                            </div>
                            <a href={aa}>  <button className='investor_button'> click here </button></a>
                        </div>
                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>ab. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}>  Brief profile of board of directors including directorship and full-time positions in body corporates </p>
                            </div>
                            <a href={ab}>  <button className='investor_button'> click here </button></a>
                        </div>

                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>b. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Terms and Conditions of appointment of Independent Director </p>
                            </div>
                            <a href={b} target='blank'>   <button className='investor_button'>click here</button></a>
                        </div>



                        <div className='investor_row_data-fina'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>c. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Composition of various committees of Board of Directors</p>
                            </div>

                            <ul style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                <li>List of Committee_w.e.f Nov 19, 2024</li>
                                <a href={bd4} target='blank'>  <button className='investor_button'>click here</button></a>
                            </ul>


                        </div>


                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>d. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Code of Conduct of Board and Senior Management Personnel </p>
                            </div>
                            <a href={cc} target='blank'>  <button className='investor_button'>click here</button></a>
                        </div>


                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>e. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}>	Criteria for making payment to non executive directors, if the same has <br /> not been disclosed in the annual report  </p>
                            </div>
                            <button className='investor_button'>click here</button>
                        </div>



                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>f. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Details of Familairization Programme imparted to Independent Director </p>
                            </div>
                            <Link to={'/details-familairization '}> <button className='investor_button'>click here</button></Link>
                        </div>

                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>g. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> AGM/ EGM/ Postal Ballot </p>
                            </div>
                            <Link to={'/annual-report'}>   <button className='investor_button'>click here</button> </Link>
                        </div>






                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>h. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Financial Results </p>
                            </div>
                            <Link to={'/financial-result'}>  <button className='investor_button'>click here</button></Link>
                        </div>



                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>i. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Notice </p>
                            </div>
                            <Link to={'/notices'}>  <button className='investor_button'>click here</button> </Link>
                        </div>






                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>j. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Shareholding Pattern	 </p>
                            </div>
                            <Link to={'/shareholding'}>   <button className='investor_button'>click here</button> </Link>
                        </div>



                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>k. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Schedule of Analyst or Institutional meet </p>
                            </div>
                            <Link to={'/schedule-analyst'}>  <button className='investor_button'>click here</button> </Link>
                        </div>




                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>l. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Newspaper Publishing	 </p>
                            </div>
                            <Link to={'/newspaper-publishing'}>   <button className='investor_button'>click here</button></Link>
                        </div>





                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>m. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Credit rating </p>
                            </div>
                            <Link to={'/credit-rating'}>   <button className='investor_button'>click here</button></Link>
                            
                        </div>







                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>n. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Audited Financial Statements of Subsidiaries	 </p>
                            </div>
                            <Link to={'/audited-financial-statements-of-subsidiaries'}><button className='investor_button'>click here</button></Link>
                        </div>





                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>o. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Secretarial Compliance Report	 </p>
                            </div>
                            <Link to={'/secretarial'}> <button className='investor_button'>click here</button></Link>
                        </div>

                        



                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>p. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Contact Details of KMP authorised determining materiality of event	 </p>
                            </div>
                            <a href={q} target='blank'><button className='investor_button'>click here</button></a>
                        </div>




                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>q. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 		Disclosures_Regulation 30 </p>
                            </div>
                            <Link to={'/disclosures'}>  <button className='investor_button'>click here</button> </Link>
                        </div>




                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>r. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 		Statement of deviation(s) or variation(s) </p>
                            </div>
                            <Link to={'/statement'}><button className='investor_button'>click here</button></Link>
                        </div>




                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>s. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 		Dividend Distribution Policy </p>
                            </div>
                            <a href={dividentD}>  <button className='investor_button'>click here</button></a>
                        </div>







                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>t. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Annual Return_Section 92 of Companies Act 2013	 </p>
                            </div>
                            <Link to={'/annual-return'}><button className='investor_button'>click here</button></Link>
                        </div>
                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>u. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Employee Benefit Scheme    </p>
                            </div>
                            <a href={u}><button className='investor_button'>click here</button></a>
                        </div>
                        
                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>v. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Related Party Transaction    </p>
                            </div>
                            <Link to={'/related-party-transaction'}><button className='investor_button'>click here</button></Link>
                        </div>
                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>w. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> Communication to Shareholders    </p>
                            </div>
                            <Link to={'/communication-to-shareholders'}><button className='investor_button'>click here</button></Link>
                        </div>
                        



                    </div>
                </div>
            </div >


            <div>
                <FloatingWhatsApp phoneNumber="91" accountName="" />
            </div>


            <Footer />
        </>
    )
}

export default Invester